const Style = ({ attributes, clientId }) => {
	const { alignment, width, height } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		#tdvb3DViewerBlock-${clientId} .tdvb3DViewerBlock{
			text-align: ${alignment};
		}
		#tdvb3DViewerBlock-${clientId} .tdvb3DViewerBlock model-viewer{
			width: ${['0px', '0%', '0em'].includes(width) ? 'auto' : width};
			height: ${'0px' === height || '0%' === height || '0em' === height ? '350px' : height};
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;